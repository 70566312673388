import React from 'react';
import Div100vh from 'react-div-100vh';
import cn from 'classnames';

import appImg from '@client/assets/native-error/makotools_takala_native.jpg';
import css from './NativeAppError.module.scss';

const NativeAppError = () => {
  return (
    <Div100vh className={cn(css.nativeAppError)}>
      <div className={css.content}>
        <img src={appImg} alt="Web App not installed" />
        <a role="button" aria-label="install application" href="http://onelink.to/656xt3">
          התקנה
        </a>
      </div>
    </Div100vh>
  );
};

export default NativeAppError;
