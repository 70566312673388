import React from 'react';
import gsap from 'gsap';
import cn from 'classnames';

import { withTransition, WithTransitionProps } from '@client/components/HOCs/with-transition';
import { useAppSelector, usePrev } from '@client/hooks';
import { selectProgram } from '@client/redux/program/selectors';

import css from './Card.module.scss';

type Props = WithTransitionProps & {
  className?: string;
  animationState?: 'DEFAULT' | 'OUT';
};

const Logo = (props: Props) => {
  const { className = '' } = props;
  const program = useAppSelector(selectProgram);
  const prevAnimationState = usePrev(props.animationState);
  const logoImg = program?.theme.logo;

  React.useEffect(() => {
    if (prevAnimationState !== 'DEFAULT' && props.animationState === 'DEFAULT') {
      gsap.fromTo(
        `.${css.logo}`,
        { opacity: 0, transition: '' },
        { duration: 0, opacity: 1, transition: 'opacity 0.5s ease-in' },
      );
    }

    if (prevAnimationState !== 'OUT' && props.animationState === 'OUT') {
      gsap.fromTo(
        `.${css.logo}`,
        { opacity: 1, transition: '' },
        { duration: 0, opacity: 0, transition: 'opacity 0.35s ease-in' },
      );
    }

    gsap.delayedCall(1, props.onTransitionEnd);
  }, [prevAnimationState, props.animationState, props.onTransitionEnd]);

  React.useEffect(() => {
    return () => {
      gsap.killTweensOf(`.${css.logo}`);
    };
  }, []);

  return <div className={cn(css.logo, className)}>{logoImg && <img src={logoImg} />}</div>;
};

const Animated = withTransition(Logo);
export { Animated as Logo };
