import React from 'react';

type Direction = 'top' | 'right' | 'bottom' | 'left';

type AppTouchEvent = TouchEvent;

interface Point {
  x: number;
  y: number;
}

interface Params {
  id?: string;
  distance: number;
  disabled?: boolean;
  onStart?: () => unknown;
  onEnd?: () => unknown;
  onComplete?: (direction: Direction) => unknown;
  onProgress?: (progress: number, distance: number, direction: Direction) => unknown;
}

export const useSwipe = (params: Params) => {
  const touchStartPointRef = React.useRef({ x: 0, y: 0 });
  const isActiveRef = React.useRef(false);

  const onWindowTouch = React.useCallback(
    (e: AppTouchEvent | MouseEvent) => {
      switch (e.type) {
        case 'touchstart':
        case 'mousedown': {
          if (isTouchEvent(e)) {
            touchStartPointRef.current.x = e.touches[0].clientX;
            touchStartPointRef.current.y = e.touches[0].clientY;
          } else {
            touchStartPointRef.current.x = e.clientX;
            touchStartPointRef.current.y = e.clientY;
          }
          isActiveRef.current = true;
          if (params.onStart) params.onStart();
          break;
        }

        case 'touchmove':
        case 'mousemove': {
          if (!isActiveRef.current) return;

          const currentTouchPoint: Point = {
            x: isTouchEvent(e) ? e.touches[0].clientX : e.clientX,
            y: isTouchEvent(e) ? e.touches[0].clientY : e.clientY,
          };
          const diffX = currentTouchPoint.x - touchStartPointRef.current.x;
          const diffY = currentTouchPoint.y - touchStartPointRef.current.y;

          if (params.onProgress) {
            params.onProgress(
              Math.min(Math.abs(diffX) / params.distance, 1),
              Math.abs(diffX),
              diffX > 0 ? 'right' : 'left',
            );
            params.onProgress(
              Math.min(Math.abs(diffY) / params.distance, 1),
              Math.abs(diffY),
              diffY > 0 ? 'top' : 'bottom',
            );
          }

          if (params.onComplete) {
            if (Math.abs(diffX) >= params.distance) {
              params.onComplete(diffX > 0 ? 'right' : 'left');
              isActiveRef.current = false;
            }
            if (Math.abs(diffY) >= params.distance) {
              params.onComplete(diffY > 0 ? 'top' : 'bottom');
              isActiveRef.current = false;
            }
          }
          break;
        }

        case 'touchend':
        case 'mouseup': {
          if (isActiveRef.current) {
            isActiveRef.current = false;
            if (params.onEnd) params.onEnd();
          }
          break;
        }

        default:
          break;
      }
    },
    [params],
  );

  React.useEffect(() => {
    if (params.disabled !== true) {
      window.addEventListener('touchstart', onWindowTouch);
      window.addEventListener('touchmove', onWindowTouch);
      window.addEventListener('touchend', onWindowTouch);

      window.addEventListener('mousedown', onWindowTouch);
      window.addEventListener('mousemove', onWindowTouch);
      window.addEventListener('mouseup', onWindowTouch);
    }

    return () => {
      window.removeEventListener('touchstart', onWindowTouch);
      window.removeEventListener('touchmove', onWindowTouch);
      window.removeEventListener('touchend', onWindowTouch);

      window.removeEventListener('mousedown', onWindowTouch);
      window.removeEventListener('mousemove', onWindowTouch);
      window.removeEventListener('mouseup', onWindowTouch);
    };
  }, [onWindowTouch, params.disabled]);
};

// safari bug: TouchEvent is undefined
const isTouchEvent = (e: AppTouchEvent | MouseEvent): e is AppTouchEvent => {
  return 'touches' in e;
};
