import { createAsyncThunk } from '@reduxjs/toolkit';

import { analytics } from '@client/utils/analytics';
import { api } from '@client/redux/api/enhanced';
import { RootState } from '@client/redux';
import { Model as PollCardModel, Answer } from '@common/models/card/poll';
import { selectProgram } from '@client/redux/program/selectors';
import { isResultWithData } from '@client/redux/program/actions/helpers';
import { selectCard } from '@client/redux/card/selectors';
import { PollCardLocalStorageData } from '@client/redux/card/types';
import { ls } from '@client/utils/local-storage';

type Params = Pick<PollCardModel, 'id'> & Pick<Answer, 'xmlAnswerId'>;
type Result = string[];

export const setVote = createAsyncThunk<Result, Params, { rejectValue: string }>(
  'card/set-vote',
  (params, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState() as RootState;
      const card = selectCard(state);
      const program = selectProgram(state);

      if (program && card) {
        // call backend api only if registration is not 'fake':
        if (program.stage === 'N') {
          const pollVote = api.endpoints.PollVote.initiate({
            answerId: params.xmlAnswerId,
            programId: program.id,
            activityId: card.id,
          });

          // no need to prolongate action by this request
          // so use .then instead of await:
          dispatch(pollVote).then((pollVoteResult) => {
            if (!isResultWithData(pollVoteResult)) {
              analytics.gtag.event(
                `OfflineVoteCardId_${card.id}-${params.xmlAnswerId}`,
                'Offline_Poll_vote',
                `OfflineAnswerId_${params.xmlAnswerId}`,
              );
            } else {
              analytics.gtag.event(
                `VoteCardId_${card.id}-${params.xmlAnswerId}`,
                `Poll_vote`,
                `AnswerId_${params.xmlAnswerId}`,
              );
            }
          });
        } else {
          analytics.gtag.event(
            `OfflineVoteCardId_${card.id}-${params.xmlAnswerId}`,
            'Offline_Poll_vote',
            `OfflineAnswerId_${params.xmlAnswerId}`,
          );
        }

        const localProgramData = ls.getData(program.id);
        const localCardData: PollCardLocalStorageData | undefined = localProgramData?.[card.id];
        const votes = localCardData?.votes || [];

        votes.push(params.xmlAnswerId);
        ls.setData(program.id, { [card.id]: { votes, voteStage: 'done' } } as Record<string, PollCardLocalStorageData>);

        return votes;
      }
      throw new Error(`Program and card at store should be defined: programId: ${program?.id}, cardId: ${card?.id}`);
    } catch (e) {
      return rejectWithValue((e as Error).message);
    }
  },
);
