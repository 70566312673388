import { createSelector } from 'reselect';
import { RootState } from '@client/redux';

export const selectProgram = (state: RootState) => {
  return state.program;
};

export const selectProgramTheme = createSelector(selectProgram, (program) => {
  return program?.theme;
});

export const selectProgramTexts = createSelector(selectProgram, (program) => {
  return program?.texts;
});
