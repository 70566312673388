import React from 'react';
import gsap from 'gsap';
import cn from 'classnames';

import { withTransition, WithTransitionProps } from '@client/components/HOCs/with-transition';
import { LoaderIcon } from '@client/components/pages/Main/Card/common/LoaderIcon';
import { usePrev } from '@client/hooks';
import css from './Card.module.scss';
import { CARD_TYPE } from '@common/models/card';

type Props = WithTransitionProps & {
  className?: string;
  cardType?: CARD_TYPE;
  bgColor?: string;
  animationState?: 'DEFAULT' | 'DROP_TO_BOTTOM' | 'OUT' | 'OUT_SCALE' | 'OUT_WIDE_BUTTON';
};

const Loader = (props: Props) => {
  const { cardType, bgColor, className = '' } = props;
  const loaderRef = React.useRef<HTMLDivElement>(null);
  const delayedTransitionEndRef = React.useRef<GSAPTween | null>(null);
  const prevAnimationState = usePrev(props.animationState);

  React.useEffect(() => {
    const containerHeight = loaderRef.current?.parentElement?.offsetHeight || 0;

    if (prevAnimationState !== 'DEFAULT' && props.animationState === 'DEFAULT') {
      gsap.fromTo(
        `.${css.loader}`,
        {
          opacity: 0,
          display: 'block',
          width: 44,
          x: `-50%`,
          y: `${-containerHeight / 2}px`,
          transition: '',
        },
        { duration: 0, opacity: 1, scale: 1, transition: 'opacity 0.5s ease-in, transform 0.5s ease-in' },
      );
      gsap.set(`.${css.loaderIcon}`, { opacity: 1 });
    }

    if (prevAnimationState !== 'DROP_TO_BOTTOM' && props.animationState === 'DROP_TO_BOTTOM') {
      gsap.fromTo(`.${css.loaderIcon}`, { opacity: 1 }, { duration: 0.5, opacity: 0 });
      gsap.fromTo(
        `.${css.loader}`,
        { display: 'block', x: `-50%`, y: `${-containerHeight / 2}px`, transition: '' },
        { duration: 1, delay: 0, x: `-50%`, y: `-69px`, ease: 'back.out(1.7)' },
      );
    }

    if (prevAnimationState !== 'OUT_WIDE_BUTTON' && props.animationState === 'OUT_WIDE_BUTTON') {
      gsap.fromTo(`.${css.loader}`, { width: 44 }, { duration: 0.5, width: 220 });
      gsap.fromTo(
        `.${css.loader}`,
        { opacity: 1 },
        {
          duration: 0.35,
          opacity: 0,
          delay: 0.5,
        },
      );
    }

    if (prevAnimationState !== 'OUT_SCALE' && props.animationState === 'OUT_SCALE') {
      gsap.fromTo(`.${css.loader}`, { transition: '' }, { duration: 0.5, opacity: 0, scale: 0 });
    }

    if (prevAnimationState !== 'OUT' && props.animationState === 'OUT') {
      gsap.fromTo(`.${css.loader}`, { transition: '' }, { duration: 0.5, opacity: 0 });
    }

    delayedTransitionEndRef.current?.kill();
    delayedTransitionEndRef.current = gsap.delayedCall(1, props.onTransitionEnd);
  }, [prevAnimationState, props.animationState, props.onTransitionEnd]);

  React.useEffect(() => {
    return () => {
      gsap.killTweensOf([`.${css.loader}`, `.${css.loaderIcon}`]);
    };
  }, []);

  return (
    <div className={cn(css.loader, className)} style={{ backgroundColor: bgColor ?? '' }} ref={loaderRef}>
      <LoaderIcon cardType={cardType} bgColor={bgColor} />
    </div>
  );
};

const Animated = withTransition(Loader, { outStates: ['OUT', 'OUT_SCALE', 'OUT_WIDE_BUTTON'] });
export { Animated as Loader };
