import { createApi, retry } from '@reduxjs/toolkit/query/react';
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query';
import { GraphQLClient } from 'graphql-request';

import { IS_DEV, DOMAIN } from '@client/constants';

// https://github.com/prisma-labs/graphql-request/issues/103#issuecomment-866646237
const createFetchWithTimeout = (timeout: number) => async (input: RequestInfo, init?: RequestInit) => {
  if (init?.signal?.aborted) {
    throw new Error(`Aborted reason: ${init.signal.reason}`);
  }

  const controller = new AbortController();
  const timerId = setTimeout(() => {
    controller.abort();
  }, timeout);

  try {
    return await fetch(input, { ...init, signal: controller.signal });
  } finally {
    clearTimeout(timerId);
  }
};

export const client = new GraphQLClient(`https://${IS_DEV ? 'live.' : ''}${DOMAIN}/api/gql`, {
  fetch: createFetchWithTimeout(5000),
});

export const api = createApi({
  baseQuery: retry(graphqlRequestBaseQuery({ client }), {
    maxRetries: 3,
    backoff: async () => {
      await new Promise((r) => setTimeout(r, 2000));
    },
  }),
  endpoints: () => ({}),
});
