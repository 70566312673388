import { createAsyncThunk } from '@reduxjs/toolkit';

import { ls } from '@client/utils/local-storage';
import { api } from '@client/redux/api/enhanced';
import { selectProgram } from '@client/redux/program/selectors';
import { RootState } from '@client/redux';
import { ProgramState } from '../types';

export type Params = void;
type Result = { stage: ProgramState['stage']; isPristine: boolean };

export const dismissRegistration = createAsyncThunk<Result, Params, { rejectValue: string }>(
  'program/dismiss-registration',
  async (params, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState() as RootState;
      const program = selectProgram(state);

      if (program) {
        // localStorage.<programId>.stage field responds to program registration stage
        // undefined — user have not yet pass registration
        // N — 'normal' registration result
        // F — 'failed' registration result

        const stage: ProgramState['stage'] = 'N'; // need to set 'N' to work with backend api later
        ls.setData(program.id, { stage });

        const getLatestActivity = api.endpoints.GetLatestActivity.initiate({ programId: program.id });
        const getLatestActivityResult = await dispatch(getLatestActivity);
        const isPristine = getLatestActivityResult.data?.getLatestActivity === null;

        // stats:
        const viewProgram = api.endpoints.ViewProgram.initiate({ programId: program.id });
        dispatch(viewProgram);

        return { stage, isPristine };
      }

      throw new Error('Can not dismiss: no program in store');
    } catch (e) {
      return rejectWithValue((e as Error).message);
    }
  },
);
