const linkMarkup = (text?: string, url = '') => {
  if (!text) return '';

  let result = text;
  const linkTextRe = /\{(.*)\}/i;
  const linkTextMatch = text.match(linkTextRe);

  if (linkTextMatch && linkTextMatch[1]) {
    const linkText = linkTextMatch[1];
    result = result.replace(linkTextRe, `<a href="${url}" target="_self">${linkText}</a>`);
  }

  return result;
};

export { linkMarkup };
