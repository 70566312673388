import React from 'react';
import Div100vh from 'react-div-100vh';
import cn from 'classnames';

import css from './NotFound.module.scss';

const NotFound = () => {
  return (
    <Div100vh className={cn(css.notFound)}>
      <p className={css.message}>404</p>
    </Div100vh>
  );
};

export default NotFound;
