import React from 'react';
import debounce from 'lodash/debounce';

import { analytics } from '@client/utils/analytics';
import { isAndroid } from '@client/utils/is-android';
import { useAppDispatch, useAppSelector } from '@client/hooks';
import { selectProgram } from '@client/redux/program/selectors';
import { selectCard } from '@client/redux/card/selectors';
import { NativeBridge } from '@client/utils/native-bridge';
import * as cardActions from '@client/redux/card/actions';

import { Context, PubNubMessage, useContextReducer } from './context';
import { ModeratorAction } from './actions';
import { aggregateCard, aggregateUpdate } from './helpers';

type Props = React.PropsWithChildren<unknown>;

export const PubnubConnector = ({ children }: Props) => {
  const appDispatch = useAppDispatch();
  const program = useAppSelector(selectProgram);
  const card = useAppSelector(selectCard);
  const programId = program?.id || '';
  const registrationType = program?.registration?.type;
  const [state, dispatch] = useContextReducer({ programId });

  React.useEffect(() => {
    if (!programId || !program?.stage || state.isInitialized) return;
    let isRedirecting = false;

    dispatch({ type: 'SET_INITIALIZATION_STATE', payload: true });
    setup();

    async function setup() {
      const DEBOUNCE_TIME = 1000;
      const processMessageStack = (messageStack: PubNubMessage<ModeratorAction>[]) => {
        const updateData = aggregateUpdate(messageStack);

        if (updateData) {
          appDispatch(cardActions.update(updateData));
          console.log('update store:', timestamp(), updateData);
        }
        messageStack.length = 0;
      };
      let processMessageStackDebounced = debounce(processMessageStack, DEBOUNCE_TIME, {
        leading: true,
        trailing: true,
      });

      const processRedirect = (message: ModeratorAction) => {
        if (isRedirecting) return;
        if (message.type === 'action') {
          if (message.secret === window.btoa(programId)) {
            console.log('pubnub.redirect secret match');
            setTimeout(() => {
              window.location.replace(message.url);
            }, Math.max(1_000, Math.random() * 10_000));
          } else {
            console.log('pubnub.redirect secret missmatch');
          }
        }

        isRedirecting = true;
      };

      try {
        const messagesStack: PubNubMessage<ModeratorAction>[] = [];
        let updateProcessMessageStackTimeoutID: ReturnType<typeof setTimeout>;

        const setDebouncerMode = (mode: 'standby' | 'normal') => {
          switch (mode) {
            case 'normal':
              clearTimeout(updateProcessMessageStackTimeoutID);
              updateProcessMessageStackTimeoutID = setTimeout(() => {
                processMessageStackDebounced = debounce(processMessageStack, DEBOUNCE_TIME, {
                  leading: true,
                  trailing: true,
                });
              }, DEBOUNCE_TIME * 5);
              break;

            case 'standby':
              clearTimeout(updateProcessMessageStackTimeoutID);
              processMessageStackDebounced = debounce(processMessageStack, DEBOUNCE_TIME * 0.5, {
                leading: false,
                trailing: true,
              });
              break;

            default:
              break;
          }
        };

        // When app is running inside Mako app, there is no blur / focus events, and pubnub fails to restore
        // its connection. As quick fix it is decided to detect in some other way when user returns to page:
        // check for document.visibilityState === 'visible' and reload our app.
        document.addEventListener('visibilitychange', () => {
          if (document.visibilityState === 'visible') {
            console.log('isAndroid()', isAndroid());
            if (isAndroid() && (registrationType === 'Native' || registrationType === 'FormAndNative')) {
              console.log('NativeBridge.back()');
              NativeBridge.callWithCallback('back', () => console.log('closed'));
            }
            window.location.reload(); // - this line causes new tab opening...
          }
        });

        window.addEventListener('blur', () => {
          setDebouncerMode('standby');
        });
        window.addEventListener('focus', () => {
          setDebouncerMode('normal');
        });

        state.pubnub.addListener({
          status: (event) => {
            switch (event.category) {
              case 'PNConnectedCategory': {
                console.log(`%cpubnub is connected`, timestamp(), 'color: greenyellow');
                break;
              }

              case 'PNNetworkDownCategory': {
                console.log(`%cpubnub is disconnected`, timestamp(), 'color: red');
                setDebouncerMode('standby');
                break;
              }

              case 'PNNetworkUpCategory': {
                console.log(`%cpubnub is reconnected`, timestamp(), 'color: greenyellow');
                setDebouncerMode('normal');
                break;
              }

              default:
                break;
            }
          },
          message: (event) => {
            const parsedMessage = JSON.parse(event.message) as PubNubMessage<ModeratorAction>;

            switch (parsedMessage.type) {
              case 'activity_stage_update':
                console.log('pubnub.message:', timestamp(), parsedMessage.activity.id, parsedMessage.activity.stage);

                messagesStack.push(parsedMessage);
                processMessageStackDebounced(messagesStack);
                break;

              case 'action':
                console.log('pubnub.redirect');
                processRedirect(parsedMessage);
                break;

              default:
                break;
            }
          },
        });

        // call pubnub.history only if backend.api.getLatestActivity failed:
        if (!card && !program?.isPristine) {
          const history = await state.pubnub.fetchMessages({ channels: [programId], count: 5 });
          const messages = history.channels[programId];
          const currentCard = aggregateCard(
            messages?.map((message) => (message ? JSON.parse(message.message) : undefined)),
          );

          if (currentCard) {
            console.log('pubnub.initial card:', currentCard);
            appDispatch(cardActions.init({ programId, card: currentCard }));
          } else {
            console.log('no pubnub.initial card');
          }

          analytics.gtag.event(`Offline_start_${programId}`, 'Offline_start', programId);
        } else {
          const reason = card ? 'already has card' : `program.isPristine: ${program?.isPristine}`;
          console.log('skip pubnub.history', `(${reason})`);
        }

        state.pubnub.subscribe({ channels: [programId], withPresence: false });
        dispatch({ type: 'SET_CONNECTION_STATE', payload: true });
      } catch (e) {
        console.log(`%cPubNub error ${(e as Error).message}`, 'color: red');
      }
    }
  }, [programId, program?.isPristine, program?.stage, registrationType, card, state, dispatch, appDispatch]);

  return <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>;
};

const timestamp = () => {
  const date = new Date();

  return `${str(date.getHours())}:${str(date.getMinutes())}:${str(date.getSeconds())}.${str(date.getMilliseconds())}`;

  function str(num: number) {
    return num < 10 ? `0${num}` : `${num}`;
  }
};
