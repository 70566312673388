import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { use100vh } from 'react-div-100vh';
import cn from 'classnames';
import queryString from 'query-string';

import { useAppDispatch, useAppSelector, usePrev } from '@client/hooks';
import { isAndroid } from '@client/utils/is-android';
import { NOT_FOUND } from '@client/constants/routes';
import { selectProgram } from '@client/redux/program/selectors';
import * as programActions from '@client/redux/program/actions';

import { Card } from './Card';
import { Landscape } from './Landscape';
import css from './Main.module.scss';

const Main = () => {
  const [isInitialRenderPassed, setIsInitialRenderPassed] = React.useState(false);
  const [isOnline, setIsOnline] = React.useState(true);
  const [isLandscape, setIsLandscape] = React.useState(false);
  const program = useAppSelector(selectProgram);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const registrationType = program?.registration?.type;
  const shouldUse100VH = isAndroid() && (registrationType === 'Native' || registrationType === 'FormAndNative');
  const preHeightRef = React.useRef(0);

  // iOS 15 keyboard issue fix (not allow reduce viewport height on keybord active):
  const actualHeight = use100vh() || 0;
  preHeightRef.current = Math.max(actualHeight, preHeightRef.current);
  const height = shouldUse100VH ? '100vh' : preHeightRef.current;

  React.useEffect(() => {
    if (shouldUse100VH) {
      document.querySelector('html')?.classList.add('full-vh');
    }
  }, [shouldUse100VH]);

  React.useEffect(() => {
    const queryParams = queryString.parse(location.search);

    if (typeof queryParams.id === 'string') {
      dispatch(programActions.init({ id: queryParams.id, navigate }));
    } else {
      console.log('No "id" provided, check url query ?id');
      navigate(NOT_FOUND);
    }
  }, [location, navigate, dispatch]);

  React.useEffect(() => {
    const onNetworkConnectionChange = (e: Event) => {
      switch (e.type) {
        case 'online':
          setIsOnline(true);
          break;

        case 'offline':
          setIsOnline(false);
          break;

        default:
          break;
      }
    };

    const onWindowResize = () => {
      const vpWidth = window.innerWidth;
      const vpHeight = window.innerHeight;

      setIsLandscape(vpHeight < vpWidth);
    };

    onWindowResize();

    window.addEventListener('offline', onNetworkConnectionChange);
    window.addEventListener('online', onNetworkConnectionChange);
    window.addEventListener('resize', onWindowResize);
    window.addEventListener('orientationchange', onWindowResize);

    // We need to have Div100vh do its job before card mounting
    // because loader is relied on viewport height.
    setIsInitialRenderPassed(true);

    return () => {
      window.removeEventListener('offline', onNetworkConnectionChange);
      window.removeEventListener('online', onNetworkConnectionChange);
      window.removeEventListener('resize', onWindowResize);
      window.removeEventListener('orientationchange', onWindowResize);
    };
  }, []);

  return (
    <div className={cn(css.main, !isOnline && css.offline)} style={{ height }}>
      {/* {isLandscape && <Landscape />} */}
      {isInitialRenderPassed && <Card />}
    </div>
  );
};

export default Main;
