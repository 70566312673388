import { createAsyncThunk } from '@reduxjs/toolkit';

import { analytics } from '@client/utils/analytics';
import { api } from '@client/redux/api/enhanced';
import { RisingStarVote, YesNoVote } from '@client/redux/api/generated';
import { RootState } from '@client/redux';
import {
  RisingStarCardLocalStorageData,
  RisingStarCardState,
  YesNoCardLocalStorageData,
} from '@client/redux/card/types';
import { selectProgram } from '@client/redux/program/selectors';
import { isResultWithData } from '@client/redux/program/actions/helpers';
import { selectCard } from '@client/redux/card/selectors';
import { ls } from '@client/utils/local-storage';

type Params = Required<Pick<RisingStarCardState, 'answer'>>;
type Result = Params;

export const setAnswer = createAsyncThunk<Result, Params, { rejectValue: string }>(
  'card/set-answer',
  (params, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState() as RootState;
      const card = selectCard(state);
      const program = selectProgram(state);

      if (program && card) {
        const analyticsAnswer = params.answer === 'yes' ? 'Yes' : 'No';

        switch (card.type) {
          case 'RisingStar': {
            // call backend api only if registration is not 'fake':
            if (program.stage === 'N') {
              const risingStarVote = api.endpoints.RisingStarVote.initiate({
                answerId: params.answer === 'yes' ? RisingStarVote.Yes : RisingStarVote.No,
                programId: program.id,
                activityId: card.id,
              });

              // no need to prolongate action by this request
              // so use .then instead of await:
              dispatch(risingStarVote).then((activityCheckInResult) => {
                if (!isResultWithData(activityCheckInResult)) {
                  analytics.gtag.event(
                    `Offline_RSvote_${analyticsAnswer}_${card.id}`,
                    'Offline_risingstar_vote',
                    `Offline_RSvote_${analyticsAnswer}_${card.id}`,
                  );
                } else {
                  analytics.gtag.event(
                    `RSvote_${analyticsAnswer}_${card.id}`,
                    `risingstar_vote`,
                    `RSvote_${analyticsAnswer}_${card.id}`,
                  );
                }
              });
            } else {
              analytics.gtag.event(
                `Offline_RSvote_${analyticsAnswer}_${card.id}`,
                'Offline_risingstar_vote',
                `Offline_RSvote_${analyticsAnswer}_${card.id}`,
              );
            }

            ls.setData(program.id, { [card.id]: { answer: params.answer } } as Record<
              string,
              RisingStarCardLocalStorageData
            >);
            break;
          }
          case 'YesNo': {
            // call backend api only if registration is not 'fake':
            if (program.stage === 'N') {
              const yesNoVote = api.endpoints.YesNoVote.initiate({
                answerId: params.answer === 'yes' ? YesNoVote.Yes : YesNoVote.No,
                programId: program.id,
                activityId: card.id,
              });

              // no need to prolongate action by this request
              // so use .then instead of await:
              dispatch(yesNoVote).then((activityCheckInResult) => {
                if (!isResultWithData(activityCheckInResult)) {
                  analytics.gtag.event(
                    `Offline_YNvote_${analyticsAnswer}_${card.id}`,
                    'Offline_yesno_vote',
                    `Offline_YNvote_${analyticsAnswer}_${card.id}`,
                  );
                } else {
                  analytics.gtag.event(
                    `YNvote_${analyticsAnswer}_${card.id}`,
                    `yesno_vote`,
                    `YNvote_${analyticsAnswer}_${card.id}`,
                  );
                }
              });
            } else {
              analytics.gtag.event(
                `Offline_YNvote_${analyticsAnswer}_${card.id}`,
                'Offline_yesno_vote',
                `Offline_YNvote_${analyticsAnswer}_${card.id}`,
              );
            }

            ls.setData(program.id, { [card.id]: { answer: params.answer } } as Record<
              string,
              YesNoCardLocalStorageData
            >);
            break;
          }
          default:
            break;
        }

        return params;
      }
      throw new Error(`Program and card at store should be defined: programId: ${program?.id}, cardId: ${card?.id}`);
    } catch (e) {
      return rejectWithValue((e as Error).message);
    }
  },
);
