import React from 'react';
import gsap from 'gsap';
import cn from 'classnames';

import { withTransition, WithTransitionProps } from '@client/components/HOCs/with-transition';
import { NativeBridge } from '@client/utils/native-bridge';
import { usePrev } from '@client/hooks';
import css from './Card.module.scss';

type Props = WithTransitionProps & {
  className?: string;
  animationState?: 'DEFAULT' | 'OUT';
};

const CloseBtn = (props: Props) => {
  const { className = '' } = props;
  const prevAnimationState = usePrev(props.animationState);
  const onTransitionEndDelayedCallRef = React.useRef<GSAPTween | null>(null);

  const onClick = React.useCallback(() => {
    NativeBridge.callWithCallback('back', () => console.log('closed'));
  }, []);

  React.useEffect(() => {
    if (prevAnimationState !== 'DEFAULT' && props.animationState === 'DEFAULT') {
      gsap.fromTo(
        `.${css.closeBtn}`,
        { scale: 0, rotation: 0 },
        { duration: 1, scale: 1, rotation: 180, ease: 'back.out(1.7)' },
      );
    }

    onTransitionEndDelayedCallRef.current?.kill();
    onTransitionEndDelayedCallRef.current = gsap.delayedCall(1, props.onTransitionEnd);
  }, [prevAnimationState, props.animationState, props.onTransitionEnd]);

  React.useEffect(() => {
    return () => {
      gsap.killTweensOf(`.${css.closeBtn}`);
    };
  }, []);

  return (
    <button className={cn(css.closeBtn, className)} type="button" onClick={onClick}>
      <span className={css.closeBtnIcon} />
    </button>
  );
};

const Animated = withTransition(CloseBtn);
export { Animated as CloseBtn };
