import { api } from 'src/redux/api/index';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  JSONObject: any;
};

export type Activity = InfoActivity | PollActivity | RisingStarActivity | YesNoActivity;

export enum ActivityStages {
  Hide = 'Hide',
  Show = 'Show',
  StartCheckIn = 'StartCheckIn',
  StartVote = 'StartVote',
  StopCheckIn = 'StopCheckIn',
  StopVote = 'StopVote',
}

export enum ActivityTypes {
  Info = 'Info',
  Poll = 'Poll',
  RisingStar = 'RisingStar',
  YesNo = 'YesNo',
}

export type InfoActivity = {
  __typename?: 'InfoActivity';
  animationUrl?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  descriptionLink?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  mediaPlacement?: Maybe<Scalars['String']>;
  mediaUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  stage: ActivityStages;
  title: Scalars['String'];
  type: ActivityTypes;
};

export type Mutation = {
  __typename?: 'Mutation';
  activityCheckIn: ProgramSession;
  createProgramSession: ProgramSession;
  pollVote: ProgramSession;
  risingStarVote: ProgramSession;
  viewActivity: Scalars['String'];
  viewProgram: Scalars['String'];
  yesNoVote: ProgramSession;
};

export type MutationActivityCheckInArgs = {
  activityId: Scalars['String'];
  programId: Scalars['String'];
};

export type MutationCreateProgramSessionArgs = {
  nativeHash?: InputMaybe<Scalars['String']>;
  programId: Scalars['String'];
  register?: InputMaybe<ProgramSessionRegisterInput>;
};

export type MutationPollVoteArgs = {
  activityId: Scalars['String'];
  answerId: Scalars['String'];
  programId: Scalars['String'];
};

export type MutationRisingStarVoteArgs = {
  activityId: Scalars['String'];
  answerId: RisingStarVote;
  programId: Scalars['String'];
};

export type MutationViewActivityArgs = {
  activityId: Scalars['String'];
  programId: Scalars['String'];
};

export type MutationViewProgramArgs = {
  programId: Scalars['String'];
};

export type MutationYesNoVoteArgs = {
  activityId: Scalars['String'];
  answerId: YesNoVote;
  programId: Scalars['String'];
};

export type PollActivity = {
  __typename?: 'PollActivity';
  answerType: PollAnswerType;
  answers: Array<PollActivityAnswer>;
  continue?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  numOfVotesAllowed?: Maybe<Scalars['Int']>;
  question: Scalars['String'];
  stage: ActivityStages;
  thankYou?: Maybe<Scalars['String']>;
  type: ActivityTypes;
  xmlVoteId?: Maybe<Scalars['String']>;
};

export type PollActivityAnswer = {
  __typename?: 'PollActivityAnswer';
  image: Scalars['String'];
  text?: Maybe<Scalars['String']>;
  xmlAnswerId: Scalars['String'];
};

export enum PollAnswerType {
  Images = 'Images',
  TextAndImages = 'TextAndImages',
}

export type ProgramSession = {
  __typename?: 'ProgramSession';
  activities: Scalars['JSONObject'];
  id: Scalars['String'];
  register?: Maybe<ProgramSessionRegister>;
};

export type ProgramSessionRegister = {
  __typename?: 'ProgramSessionRegister';
  CheckboxOne?: Maybe<Scalars['Boolean']>;
  CheckboxTwo?: Maybe<Scalars['Boolean']>;
  CustomTextFieldOne?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  FullName?: Maybe<Scalars['String']>;
  Phone?: Maybe<Scalars['String']>;
};

export type ProgramSessionRegisterInput = {
  CheckboxOne?: InputMaybe<Scalars['Boolean']>;
  CheckboxTwo?: InputMaybe<Scalars['Boolean']>;
  CustomTextFieldOne?: InputMaybe<Scalars['String']>;
  Email?: InputMaybe<Scalars['String']>;
  FullName?: InputMaybe<Scalars['String']>;
  Phone?: InputMaybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  getLatestActivity?: Maybe<Activity>;
  getProgramSession?: Maybe<ProgramSession>;
};

export type QueryGetLatestActivityArgs = {
  programId: Scalars['String'];
};

export type QueryGetProgramSessionArgs = {
  programId: Scalars['String'];
};

export type RisingStarActivity = {
  __typename?: 'RisingStarActivity';
  continue?: Maybe<Scalars['String']>;
  disqualifiedText: Scalars['String'];
  finalText?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  qualifiedText: Scalars['String'];
  questionText: Scalars['String'];
  showFlare?: Maybe<Scalars['Boolean']>;
  singerImage: Scalars['String'];
  singerName: Scalars['String'];
  songName: Scalars['String'];
  stage: ActivityStages;
  thankYou?: Maybe<Scalars['String']>;
  type: ActivityTypes;
  xmlVoteId?: Maybe<Scalars['String']>;
};

export enum RisingStarVote {
  No = 'No',
  Yes = 'Yes',
}

export type YesNoActivity = {
  __typename?: 'YesNoActivity';
  continue?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  mainColor: Scalars['String'];
  mainImage: Scalars['String'];
  name: Scalars['String'];
  noBgColor: Scalars['String'];
  noIcon: Scalars['String'];
  noText: Scalars['String'];
  questionText: Scalars['String'];
  showFlare?: Maybe<Scalars['Boolean']>;
  stage: ActivityStages;
  thankYou?: Maybe<Scalars['String']>;
  type: ActivityTypes;
  xmlVoteId?: Maybe<Scalars['String']>;
  yesBgColor: Scalars['String'];
  yesIcon: Scalars['String'];
  yesText: Scalars['String'];
};

export enum YesNoVote {
  No = 'No',
  Yes = 'Yes',
}

export type RegisterFragment = {
  __typename?: 'ProgramSessionRegister';
  FullName?: string | null;
  Email?: string | null;
  Phone?: string | null;
  CustomTextFieldOne?: string | null;
  CheckboxOne?: boolean | null;
  CheckboxTwo?: boolean | null;
};

export type ActivityCheckInMutationVariables = Exact<{
  activityId: Scalars['String'];
  programId: Scalars['String'];
}>;

export type ActivityCheckInMutation = {
  __typename?: 'Mutation';
  activityCheckIn: {
    __typename?: 'ProgramSession';
    id: string;
    activities: any;
    register?: {
      __typename?: 'ProgramSessionRegister';
      FullName?: string | null;
      Email?: string | null;
      Phone?: string | null;
      CustomTextFieldOne?: string | null;
      CheckboxOne?: boolean | null;
      CheckboxTwo?: boolean | null;
    } | null;
  };
};

export type CreateProgramSessionMutationVariables = Exact<{
  programId: Scalars['String'];
  register?: InputMaybe<ProgramSessionRegisterInput>;
  nativeHash?: InputMaybe<Scalars['String']>;
}>;

export type CreateProgramSessionMutation = {
  __typename?: 'Mutation';
  createProgramSession: {
    __typename?: 'ProgramSession';
    id: string;
    activities: any;
    register?: { __typename?: 'ProgramSessionRegister'; FullName?: string | null } | null;
  };
};

export type GetLatestActivityQueryVariables = Exact<{
  programId: Scalars['String'];
}>;

export type GetLatestActivityQuery = {
  __typename?: 'Query';
  getLatestActivity?:
    | {
        __typename?: 'InfoActivity';
        id: string;
        name: string;
        type: ActivityTypes;
        stage: ActivityStages;
        title: string;
        description?: string | null;
        descriptionLink?: string | null;
        mediaUrl?: string | null;
        mediaPlacement?: string | null;
        animationUrl?: string | null;
      }
    | {
        __typename?: 'PollActivity';
        id: string;
        name: string;
        xmlVoteId?: string | null;
        question: string;
        type: ActivityTypes;
        stage: ActivityStages;
        answerType: PollAnswerType;
        numOfVotesAllowed?: number | null;
        thankYou?: string | null;
        continue?: string | null;
        answers: Array<{ __typename?: 'PollActivityAnswer'; xmlAnswerId: string; image: string; text?: string | null }>;
      }
    | {
        __typename?: 'RisingStarActivity';
        id: string;
        name: string;
        type: ActivityTypes;
        stage: ActivityStages;
        xmlVoteId?: string | null;
        singerName: string;
        singerImage: string;
        songName: string;
        finalText?: string | null;
        questionText: string;
        qualifiedText: string;
        disqualifiedText: string;
        thankYou?: string | null;
        continue?: string | null;
      }
    | {
        __typename?: 'YesNoActivity';
        id: string;
        name: string;
        type: ActivityTypes;
        stage: ActivityStages;
        xmlVoteId?: string | null;
        questionText: string;
        mainImage: string;
        mainColor: string;
        yesText: string;
        yesIcon: string;
        yesBgColor: string;
        noText: string;
        noIcon: string;
        noBgColor: string;
        thankYou?: string | null;
        continue?: string | null;
      }
    | null;
};

export type GetProgramSessionQueryVariables = Exact<{
  programId: Scalars['String'];
}>;

export type GetProgramSessionQuery = {
  __typename?: 'Query';
  getProgramSession?: { __typename?: 'ProgramSession'; id: string } | null;
};

export type PollVoteMutationVariables = Exact<{
  answerId: Scalars['String'];
  activityId: Scalars['String'];
  programId: Scalars['String'];
}>;

export type PollVoteMutation = { __typename?: 'Mutation'; pollVote: { __typename?: 'ProgramSession'; id: string } };

export type RisingStarVoteMutationVariables = Exact<{
  answerId: RisingStarVote;
  activityId: Scalars['String'];
  programId: Scalars['String'];
}>;

export type RisingStarVoteMutation = {
  __typename?: 'Mutation';
  risingStarVote: { __typename?: 'ProgramSession'; id: string };
};

export type ViewActivityMutationVariables = Exact<{
  activityId: Scalars['String'];
  programId: Scalars['String'];
}>;

export type ViewActivityMutation = { __typename?: 'Mutation'; viewActivity: string };

export type ViewProgramMutationVariables = Exact<{
  programId: Scalars['String'];
}>;

export type ViewProgramMutation = { __typename?: 'Mutation'; viewProgram: string };

export type YesNoVoteMutationVariables = Exact<{
  answerId: YesNoVote;
  activityId: Scalars['String'];
  programId: Scalars['String'];
}>;

export type YesNoVoteMutation = { __typename?: 'Mutation'; yesNoVote: { __typename?: 'ProgramSession'; id: string } };

export const RegisterFragmentDoc = `
    fragment Register on ProgramSessionRegister {
  FullName
  Email
  Phone
  CustomTextFieldOne
  CheckboxOne
  CheckboxTwo
}
    `;
export const ActivityCheckInDocument = `
    mutation ActivityCheckIn($activityId: String!, $programId: String!) {
  activityCheckIn(activityId: $activityId, programId: $programId) {
    id
    activities
    register {
      ...Register
    }
  }
}
    ${RegisterFragmentDoc}`;
export const CreateProgramSessionDocument = `
    mutation CreateProgramSession($programId: String!, $register: ProgramSessionRegisterInput, $nativeHash: String) {
  createProgramSession(
    programId: $programId
    register: $register
    nativeHash: $nativeHash
  ) {
    id
    register {
      FullName
    }
    activities
  }
}
    `;
export const GetLatestActivityDocument = `
    query GetLatestActivity($programId: String!) {
  getLatestActivity(programId: $programId) {
    ... on RisingStarActivity {
      id
      name
      type
      stage
      xmlVoteId
      singerName
      singerImage
      songName
      finalText
      questionText
      qualifiedText
      disqualifiedText
      thankYou
      continue
    }
    ... on YesNoActivity {
      id
      name
      type
      stage
      xmlVoteId
      questionText
      mainImage
      mainColor
      yesText
      yesIcon
      yesBgColor
      noText
      noIcon
      noBgColor
      thankYou
      continue
    }
    ... on InfoActivity {
      id
      name
      type
      stage
      title
      description
      descriptionLink
      mediaUrl
      mediaPlacement
      animationUrl
    }
    ... on PollActivity {
      id
      name
      xmlVoteId
      question
      type
      stage
      answerType
      answers {
        xmlAnswerId
        image
        text
      }
      numOfVotesAllowed
      thankYou
      continue
    }
  }
}
    `;
export const GetProgramSessionDocument = `
    query GetProgramSession($programId: String!) {
  getProgramSession(programId: $programId) {
    id
  }
}
    `;
export const PollVoteDocument = `
    mutation PollVote($answerId: String!, $activityId: String!, $programId: String!) {
  pollVote(answerId: $answerId, activityId: $activityId, programId: $programId) {
    id
  }
}
    `;
export const RisingStarVoteDocument = `
    mutation RisingStarVote($answerId: RisingStarVote!, $activityId: String!, $programId: String!) {
  risingStarVote(
    answerId: $answerId
    activityId: $activityId
    programId: $programId
  ) {
    id
  }
}
    `;
export const ViewActivityDocument = `
    mutation ViewActivity($activityId: String!, $programId: String!) {
  viewActivity(activityId: $activityId, programId: $programId)
}
    `;
export const ViewProgramDocument = `
    mutation ViewProgram($programId: String!) {
  viewProgram(programId: $programId)
}
    `;
export const YesNoVoteDocument = `
    mutation YesNoVote($answerId: YesNoVote!, $activityId: String!, $programId: String!) {
  yesNoVote(answerId: $answerId, activityId: $activityId, programId: $programId) {
    id
  }
}
    `;

const injectedRtkApi = api.injectEndpoints({
  overrideExisting: false,
  endpoints: (build) => ({
    ActivityCheckIn: build.mutation<ActivityCheckInMutation, ActivityCheckInMutationVariables>({
      query: (variables) => ({ document: ActivityCheckInDocument, variables }),
    }),
    CreateProgramSession: build.mutation<CreateProgramSessionMutation, CreateProgramSessionMutationVariables>({
      query: (variables) => ({ document: CreateProgramSessionDocument, variables }),
    }),
    GetLatestActivity: build.query<GetLatestActivityQuery, GetLatestActivityQueryVariables>({
      query: (variables) => ({ document: GetLatestActivityDocument, variables }),
    }),
    GetProgramSession: build.query<GetProgramSessionQuery, GetProgramSessionQueryVariables>({
      query: (variables) => ({ document: GetProgramSessionDocument, variables }),
    }),
    PollVote: build.mutation<PollVoteMutation, PollVoteMutationVariables>({
      query: (variables) => ({ document: PollVoteDocument, variables }),
    }),
    RisingStarVote: build.mutation<RisingStarVoteMutation, RisingStarVoteMutationVariables>({
      query: (variables) => ({ document: RisingStarVoteDocument, variables }),
    }),
    ViewActivity: build.mutation<ViewActivityMutation, ViewActivityMutationVariables>({
      query: (variables) => ({ document: ViewActivityDocument, variables }),
    }),
    ViewProgram: build.mutation<ViewProgramMutation, ViewProgramMutationVariables>({
      query: (variables) => ({ document: ViewProgramDocument, variables }),
    }),
    YesNoVote: build.mutation<YesNoVoteMutation, YesNoVoteMutationVariables>({
      query: (variables) => ({ document: YesNoVoteDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useActivityCheckInMutation,
  useCreateProgramSessionMutation,
  useGetLatestActivityQuery,
  useLazyGetLatestActivityQuery,
  useGetProgramSessionQuery,
  useLazyGetProgramSessionQuery,
  usePollVoteMutation,
  useRisingStarVoteMutation,
  useViewActivityMutation,
  useViewProgramMutation,
  useYesNoVoteMutation,
} = injectedRtkApi;
