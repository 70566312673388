export const analytics = {
  gtag: {
    event: (action: string, category: string, label: string) => {
      // @ts-expect-error gtag is added in html.head:
      const { gtag } = window;

      if (gtag) {
        console.log('gtag:', action, category, label);
        gtag('event', action, {
          event_category: category,
          event_label: label,
        });
      } else {
        console.log('%cgtag is undefined', 'color: red');
      }
    },
  },
};
