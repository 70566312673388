import { ActivitySession } from '@common/models/program/index';
import { CreateProgramSessionMutation } from '@client/redux/api/generated';
import { PollCardLocalStorageData, RisingStarCardLocalStorageData } from '@client/redux/card/types';
import { ProgramState } from '@client/redux/program/types';
import { ls } from '@client/utils/local-storage';
import { analytics } from '@client/utils/analytics';

type ProgramStage = ProgramState['stage'];
type CardLocalStorageData = PollCardLocalStorageData | RisingStarCardLocalStorageData;
type Result =
  | {
      data: CreateProgramSessionMutation;
    }
  | {
      error: unknown;
    };
export type ProgramLocalStorageData = {
  stage: ProgramStage;
  cards: Record<string, CardLocalStorageData>;
};

export const processSessionResult = (programId: string, result: Result): ProgramLocalStorageData => {
  let stage: ProgramStage = 'F';
  let localProgramData: ProgramLocalStorageData = { stage, cards: {} };

  if (isResultWithData(result)) {
    const isFake = result.data.createProgramSession.register === null;
    const cards = prepareCardsLocalStorageData(programId, result.data.createProgramSession.activities);

    stage = isFake ? 'F' : 'N';
    localProgramData = { stage, cards };
  }

  ls.setData(programId, { ...localProgramData.cards, stage });

  if (stage === 'F') {
    analytics.gtag.event(`Offline_register_${programId}`, 'Offline_register', programId);
  }

  return localProgramData;
};

type GenericResult<D> =
  | {
      data: D;
    }
  | {
      error: unknown;
    };
export const isResultWithData = <T>(result: GenericResult<T>): result is { data: T } => {
  return 'data' in result;
};

export const encodeIdentity = (identity: Record<string, unknown> = {}) => {
  return encodeURIComponent(JSON.stringify(identity));
};

export const prepareCardsLocalStorageData = (programId: string, activities: Record<string, ActivitySession>) => {
  const cards = ls.getData(programId) || {};

  Object.entries(activities).forEach(([cardId, session]) => {
    switch (session.type) {
      case 'RisingStar': {
        const cardLocalStorageData: RisingStarCardLocalStorageData = {
          answer: session.answer?.toLowerCase() as 'yes' | 'no' | undefined,
          isCheckedIn: session.checkedIn,
        };

        cards[cardId] = cardLocalStorageData;
        break;
      }

      case 'Poll': {
        const cardLocalStorageData: PollCardLocalStorageData = {
          votes: session.answers,
        };

        cards[cardId] = cardLocalStorageData;
        break;
      }

      default:
        break;
    }
  });

  return cards;
};
