import { BEModel as CardModel } from '@common/models/card';
import { api as generatedApi } from '@client/redux/api/generated';
import * as cardActions from '@client/redux/card/actions';

export const api = generatedApi.enhanceEndpoints({
  addTagTypes: ['LATEST_ACTIVITY'],
  endpoints: {
    CreateProgramSession: {
      extraOptions: {
        maxRetries: 2,
      },
    },
    GetProgramSession: {
      extraOptions: {},
    },
    GetLatestActivity: {
      onCacheEntryAdded: async (params, { cacheDataLoaded, dispatch }) => {
        const response = await cacheDataLoaded;
        if (response.data.getLatestActivity) {
          const programId = params.programId;
          const card = response.data.getLatestActivity as CardModel;
          dispatch(cardActions.init({ programId, card }));
        }
      },
      extraOptions: {
        maxRetries: 0,
      },
    },
    ActivityCheckIn: {
      extraOptions: {},
    },
    RisingStarVote: {
      extraOptions: {},
    },
    PollVote: {
      extraOptions: {},
    },
    ViewProgram: {
      extraOptions: {},
    },
    ViewActivity: {
      extraOptions: {},
    },
    YesNoVote: {
      extraOptions: {},
    },
  },
});
