export const FIREBASE_CONFIG = {
  apiKey: process.env.API_KEY,
  authDomain: process.env.AUTH_DOMAIN,
  databaseURL: process.env.DATABASE_URL,
  projectId: process.env.PROJECT_ID,
  storageBucket: process.env.STORAGE_BUCKET,
  messagingSenderId: process.env.MESSAGING_SENDER_ID,
  appId: process.env.APP_ID,
};

const IS_DEV = process.env.IS_DEV;
const WEBSERVER_PORT = process.env.WEBSERVER_PORT;
export const API_URL = !!IS_DEV ? `http://localhost:${WEBSERVER_PORT}/api` : `/api`;
export const REDUX_STORAGE_KEY = '__REDUX_STORAGE__';
