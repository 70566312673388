import { createAsyncThunk } from '@reduxjs/toolkit';

import { ls } from '@client/utils/local-storage';
import { RootState } from '@client/redux';
import { RisingStarCardModel } from '@common/models/card';
import { selectProgram } from '@client/redux/program/selectors';
import { selectCard } from '@client/redux/card/selectors';
import { PollCardLocalStorageData, PollCardState } from '@client/redux/card/types';

type Params = RisingStarCardModel['id'];
type Result = PollCardState['voteStage'];

export const voteAgain = createAsyncThunk<Result, Params, { rejectValue: string }>(
  'card/vote-again',
  (params, { rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;
      const card = selectCard(state);
      const program = selectProgram(state);

      if (program && card) {
        const voteStage: PollCardState['voteStage'] = 'inProgress';

        ls.setData(program.id, { [card.id]: { voteStage } } as Record<string, PollCardLocalStorageData>);

        return voteStage;
      }
      throw new Error(`Program and card at store should be defined: programId: ${program?.id}, cardId: ${card?.id}`);
    } catch (e) {
      return rejectWithValue((e as Error).message);
    }
  },
);
