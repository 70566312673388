import React from 'react';
import { Transition } from 'react-transition-group';
// import { TransitionProps } from 'react-transition-group/Transition';

// FIXME: Something wrong with this props type: CSSTransitionProps is a discrimination union that required
// timeout either addEndListener to be provided, but in current case of WithTransitionProps we don't want
// neither of them
/* DistributiveOmit<TransitionProps, 'timeout' | 'addEventListener'> & */
export type WithTransitionProps<T = string> = {
  in?: boolean;
  className?: string;
  children?: React.ReactNode;
  animationState?: T;
  containerRef?: React.RefObject<HTMLDivElement>;
  onTransitionEnd: () => unknown;
};

type Params<T> = {
  outStates: T[];
};

export const withTransition = <P extends WithTransitionProps>(
  Component: React.ComponentType<P>,
  params: Params<P['animationState']> = { outStates: ['OUT'] },
): React.FC<Omit<P, 'onTransitionEnd'>> => {
  return function WithTransition(props) {
    const inProp = props.animationState ? !params.outStates.includes(props.animationState) : props.in;
    const doneCbRef = React.useRef<() => unknown>();

    const addEndTransition = React.useCallback((node: HTMLElement, done: () => unknown) => {
      doneCbRef.current = done;
    }, []);

    const onTransitionEnd = React.useCallback(() => {
      if (doneCbRef.current) doneCbRef.current();
    }, []);

    return (
      <Transition {...props} in={inProp} addEndListener={addEndTransition} appear mountOnEnter unmountOnExit>
        <Component {...(props as P)} onExited={undefined} in={inProp} onTransitionEnd={onTransitionEnd}>
          {props.children}
        </Component>
      </Transition>
    );
  };
};
