import * as React from 'react';
import cn from 'classnames';

import { CARD_TYPE } from '@common/models/card';
import css from './LoaderIcon.module.scss';

type Props = {
  className?: string;
  cardType?: CARD_TYPE;
  bgColor?: string;
};

const LoaderIcon = (props: Props) => {
  const { cardType, bgColor, className = '' } = props;

  React.useEffect(() => {
    document.body.style.setProperty('--loader-icon-shading-color', bgColor ?? '');
  }, [bgColor]);

  return (
    <div
      className={cn(css.loaderIcon, cardType && css[cardType], className)}
      style={{ backgroundColor: bgColor ?? '' }}
    >
      <div className={css.shading} />
    </div>
  );
};

export { LoaderIcon };
