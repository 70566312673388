import { Model as ProgramModel } from '@common/models/program';
import { Model as CardModel } from '@common/models/card';
import { Resources } from '@client/other/resources';

import spritesheetImgUrl from '@client/assets/cards/yesNo/spritesheet/composed/arrow.png';

export const preloadAssets = async (program: ProgramModel, card: CardModel) => {
  console.log('preloading assets', `[${card.type}]`);

  const assetUrls: ({ url?: string; isForCanvas?: boolean } | undefined)[] = [];
  const fontVariants = ['Regular', 'Medium', 'Bold'];
  const paragraphs: HTMLParagraphElement[] = [];

  fontVariants.forEach((fontVariant) => {
    const paragraphElement = document.createElement('p');
    paragraphElement.style.position = 'absolute';
    paragraphElement.style.opacity = '0';
    paragraphElement.style.pointerEvents = 'none';
    paragraphElement.style.fontSize = '12px';
    paragraphElement.style.fontFamily = `FbReformaNarrow-${fontVariant}`;
    paragraphElement.innerText = `ברוכים`;

    document.body.appendChild(paragraphElement);
    paragraphs.push(paragraphElement);
  });

  await fontLoad();

  paragraphs.forEach((paragraph) => {
    document.body.removeChild(paragraph);
  });

  switch (card.type) {
    case 'Registration':
      assetUrls.push({ url: program.registration?.pic });
      break;

    case 'RisingStar':
      assetUrls.push(
        { url: spritesheetImgUrl, isForCanvas: true },
        { url: program.theme?.bgImage, isForCanvas: true },
        { url: program.theme?.logo },
        { url: card.singerImage, isForCanvas: true },
      );
      break;

    case 'YesNo':
      assetUrls.push(
        { url: program.theme?.bgImage, isForCanvas: true },
        { url: program.theme?.logo },
        { url: card.mainImage, isForCanvas: true },
        { url: card.yesIcon },
        { url: card.noIcon },
      );
      break;

    case 'Info':
      assetUrls.push(
        { url: program.theme?.bgImage, isForCanvas: true },
        { url: program.theme?.logo },
        { url: card.mediaUrl, isForCanvas: true },
        { url: card.animationUrl },
      );
      break;

    case 'Poll': {
      assetUrls.push({ url: program.theme?.bgImage, isForCanvas: true }, { url: program.theme?.logo });
      card.answers.forEach((answer) => {
        assetUrls.push({ url: answer.image });
      });
      break;
    }

    default:
      break;
  }

  await Promise.all(
    assetUrls.map((target) => (target?.url ? Resources.get(target.url, target.isForCanvas) : Promise.resolve())),
  );
  console.log('preloading assets complete', `[${card.type}]`);

  return true;
};

function fontLoad(): Promise<boolean> {
  return new Promise(async (resolve) => {
    try {
      const resultRegular = document.fonts.check('12px "FbReformaNarrow-Regular"');
      const resultMedium = document.fonts.check('12px "FbReformaNarrow-Medium"');
      const resultBold = document.fonts.check('12px "FbReformaNarrow-Bold"');

      if ([resultBold, resultMedium, resultRegular].some((result) => !result)) {
        await new Promise((r) => setTimeout(r, 350));
        return resolve(fontLoad());
      }
      resolve(true);
    } catch (e) {
      resolve(true);
    }
  });
}
