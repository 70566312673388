import { createAsyncThunk } from '@reduxjs/toolkit';

import { analytics } from '@client/utils/analytics';
import { RootState } from '@client/redux';
import { api } from '@client/redux/api/enhanced';
import { selectProgram } from '@client/redux/program/selectors';
import { isResultWithData } from '@client/redux/program/actions/helpers';
import { selectCard } from '@client/redux/card/selectors';
import { RisingStarCardLocalStorageData } from '@client/redux/card/types';
import { ls } from '@client/utils/local-storage';

type Params = void;
type Result = unknown;

export const checkIn = createAsyncThunk<Result, Params, { rejectValue: string }>(
  'card/check-in',
  async (params, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState() as RootState;
      const card = selectCard(state);
      const program = selectProgram(state);

      if (program && card) {
        // call backend api only if registration is not 'fake':
        if (program.stage === 'N') {
          const activityCheckIn = api.endpoints.ActivityCheckIn.initiate({
            programId: program.id,
            activityId: card.id,
          });
          dispatch(activityCheckIn).then((activityCheckInResult) => {
            if (!isResultWithData(activityCheckInResult)) {
              analytics.gtag.event(`Offline_Checkin_${card.id}`, 'Offline_Checking', card.id);
            } else {
              analytics.gtag.event(`CheckIn_${card.id}`, `CheckIn`, `CheckIn_${card.id}`);
            }
          });
        } else {
          analytics.gtag.event(`Offline_Checkin_${card.id}`, 'Offline_Checking', card.id);
        }

        ls.setData(program.id, { [card.id]: { isCheckedIn: true } } as Record<string, RisingStarCardLocalStorageData>);
      }
    } catch (e) {
      return rejectWithValue((e as Error).message);
    }
  },
);
