import { createAsyncThunk } from '@reduxjs/toolkit';

import { ls } from '@client/utils/local-storage';
import { api } from '@client/redux/api/enhanced';
import { selectProgram } from '@client/redux/program/selectors';
import { RootState } from '@client/redux';
import { processSessionResult, ProgramLocalStorageData } from './helpers';
import { encodeIdentity } from './helpers';

export type Params = Partial<{
  CheckboxOne: boolean;
  CheckboxTwo: boolean;
  CustomTextFieldOne: string;
  Email: string;
  FullName: string;
  Phone: string;
}>;
type Result = ProgramLocalStorageData & { isPristine: boolean };

export const submitRegistration = createAsyncThunk<Result, Params, { rejectValue: string }>(
  'program/submit-registration',
  async (params, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState() as RootState;
      const program = selectProgram(state);

      if (program) {
        const createProgramSession = api.endpoints.CreateProgramSession.initiate({
          programId: program.id,
          register: {
            ...params,
            CheckboxOne: params.CheckboxOne !== undefined ? !!params.CheckboxOne : undefined,
            CheckboxTwo: params.CheckboxTwo !== undefined ? !!params.CheckboxTwo : undefined,
          },
          nativeHash:
            program.registration?.type === 'Native' || program.registration?.type === 'FormAndNative'
              ? encodeIdentity(program.identity)
              : undefined,
        });

        // localStorage.<programId>.stage field responds to program registration stage
        // undefined — user have not yet pass registration
        // N — 'normal' registration result
        // F — 'failed' registration result

        // Before sending req to backend api, save { stage: 'F' } to local storage
        // (this will mark registration as 'passed'), after req completed, set { stage: 'N' } (if req has success)

        ls.setData(program.id, { stage: 'F' });
        const createProgramSessionResult = await dispatch(createProgramSession);
        const localProgramData = processSessionResult(program.id, createProgramSessionResult);

        // stats:
        const viewProgram = api.endpoints.ViewProgram.initiate({ programId: program.id });
        dispatch(viewProgram);

        const getLatestActivity = api.endpoints.GetLatestActivity.initiate({ programId: program.id });
        const getLatestActivityResult = await dispatch(getLatestActivity);
        const isPristine = getLatestActivityResult.data?.getLatestActivity === null;

        return { ...localProgramData, isPristine };
      }

      throw new Error('Can not submit: no program in store');
    } catch (e) {
      return rejectWithValue((e as Error).message);
    }
  },
);
