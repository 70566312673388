import _ from 'lodash';

// TODO: refactor it to work with strict types, not Record<string, any>
export const getData = (key: string): Record<string, any> | null => {
  const rawData = window.localStorage.getItem(key);

  if (rawData) {
    return JSON.parse(rawData);
  }

  return null;
};

export const setData = (key: string, data: Record<string, unknown>) => {
  const currentData = getData(key) || {};
  const updatedData = _.merge(currentData, data);

  window.localStorage.setItem(key, JSON.stringify(updatedData));
};

export const ls = {
  getData,
  setData,
};
