import React from 'react';
import gsap from 'gsap';
import { SwitchTransition, CSSTransition } from 'react-transition-group';

import { api } from '@client/redux/api/enhanced';
import { Context as PubnubConnectorContext } from '@client/components/PubnubConnector/context';
import { useAppDispatch, useAppSelector, usePrev } from '@client/hooks';
import { CARD_STAGE, Model as CardModel, RegistrationCardModel } from '@common/models/card';
import { selectCard } from '@client/redux/card/selectors';
import { selectProgram } from '@client/redux/program/selectors';
import { analytics } from '@client/utils/analytics';

import { Curtain } from './Curtain';
import { Logo } from './Logo';
import { Loader } from './Loader';
import { CloseBtn } from './CloseBtn';
import { preloadAssets } from './helpers';

import css from './Card.module.scss';

const RegistrationCard = React.lazy(() => import(/* webpackChunkName: "registration" */ './RegistrationCard'));
const InfoCard = React.lazy(() => import(/* webpackChunkName: "info" */ './InfoCard'));
const RisingStarCard = React.lazy(() => import(/* webpackChunkName: "rising-star" */ './RisingStarCard'));
const PollCard = React.lazy(() => import(/* webpackChunkName: "poll" */ './PollCard'));
const YesNoCard = React.lazy(() => import(/* webpackChunkName: "yes-no" */ './YesNoCard'));

type AnimationStates = {
  curtain?: React.ComponentProps<typeof Curtain>['animationState'];
  logo?: React.ComponentProps<typeof Logo>['animationState'];
  loader?: React.ComponentProps<typeof Loader>['animationState'];
  closeBtn?: React.ComponentProps<typeof CloseBtn>['animationState'];
};

type RegistrationCardState = RegistrationCardModel & { timestamp: number };

export const Card = () => {
  const dispatch = useAppDispatch();
  const pubnubCard = useAppSelector(selectCard);
  const program = useAppSelector(selectProgram);
  const { state: pubnubState } = React.useContext(PubnubConnectorContext);

  const card = React.useMemo(() => {
    // Until user pass registration need to substitute card from pubnub to registration card:
    if (!program) return;
    if (
      program.registration?.active &&
      (program.registration.type === 'Form' || program.registration.type === 'FormAndNative')
    ) {
      if (!program.stage) {
        const registrationCard: CardModel = {
          id: 'registration',
          type: 'Registration',
          stage: 'Show',
          name: '',
          form: program.registration,
          theme: program.theme,
          tos: program.tos,
        };

        return { ...registrationCard, timestamp: 1 };
      }
    }
    return pubnubCard;
  }, [pubnubCard, program]);

  const [isLoading, setIsLoading] = React.useState(false);
  const [currentCard, setCurrentCard] = React.useState<typeof pubnubCard | RegistrationCardState>();
  const [animationStates, setAnimationStates] = React.useState<AnimationStates>({});
  const curtainTimelineRef = React.useRef<GSAPTimeline>();
  const containerRef = React.useRef<HTMLDivElement>(null);
  const assetsRef = React.useRef(false);
  const prevCardTS = usePrev(card?.timestamp);

  const showCurtains = React.useCallback(() => {
    curtainTimelineRef.current?.kill();
    curtainTimelineRef.current = gsap
      .timeline()
      .add(gsap.delayedCall(0, () => setAnimationStates((state) => ({ ...state, curtain: 'FROM_TOP' }))));

    if (card?.id || !pubnubState.isConnected) {
      curtainTimelineRef.current
        .add(gsap.delayedCall(0, () => setAnimationStates((state) => ({ ...state, logo: 'OUT' }))))
        .add(gsap.delayedCall(0.25, () => setAnimationStates((state) => ({ ...state, loader: 'DEFAULT' }))));
    } else if (pubnubState.isConnected) {
      curtainTimelineRef.current
        .add(gsap.delayedCall(0.25, () => setAnimationStates((state) => ({ ...state, logo: 'DEFAULT' }))))
        .add(gsap.delayedCall(0, () => setAnimationStates((state) => ({ ...state, loader: 'OUT' }))));
    }
  }, [card?.id, pubnubState.isConnected]);

  const removeCurtains = React.useCallback(() => {
    console.log(currentCard?.id);
    if (!currentCard || !program?.id) return;

    gsap.delayedCall(0, () => setAnimationStates((state) => ({ ...state, curtain: 'TO_BOTTOM' })));

    let shouldStayInCenter = true;
    let shouldScaleDown = true;

    switch (currentCard.type) {
      case 'RisingStar': {
        const checkInClosedStages: CARD_STAGE[] = ['StartVote', 'StopCheckIn', 'StopVote', 'Hide'];

        shouldStayInCenter = currentCard.isCheckedIn || checkInClosedStages.includes(currentCard.stage);
        shouldScaleDown = currentCard.stage === 'StartVote';
        break;
      }

      case 'YesNo': {
        shouldScaleDown = currentCard.stage === 'StartVote';
        break;
      }

      case 'Poll': {
        shouldScaleDown = currentCard.stage !== 'StopVote';
        break;
      }

      default:
        break;
    }

    if (shouldStayInCenter === false) {
      gsap.delayedCall(0, () => setAnimationStates((state) => ({ ...state, loader: 'DROP_TO_BOTTOM' })));
      gsap.delayedCall(1, () => {
        setAnimationStates((state) => ({ ...state, loader: 'OUT_WIDE_BUTTON' }));
      });
    } else {
      gsap.delayedCall(shouldScaleDown ? 0 : 1, () =>
        setAnimationStates((state) => ({ ...state, loader: shouldScaleDown ? 'OUT_SCALE' : 'OUT' })),
      );
    }

    if (currentCard.type !== 'Registration' && currentCard.type !== 'RisingStar') {
      dispatch(api.endpoints.ViewActivity.initiate({ programId: program.id, activityId: currentCard.id }));
    }
    // TODO: seems like dependency on currentCard?.id and program?.id really enough, but need to think more about this
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCard?.id, program?.id]);

  React.useEffect(() => {
    const changeCard = async () => {
      assetsRef.current = false;
      setIsLoading(true);
      setCurrentCard(undefined);

      await Promise.all([program && card && preloadAssets(program, card), new Promise((r) => gsap.delayedCall(1, r))]);

      if (card?.timestamp !== prevCardTS) {
        assetsRef.current = true;
        setCurrentCard(card);
        setIsLoading(false);
      }
    };

    if (card?.timestamp !== prevCardTS) {
      changeCard();
    } else if (assetsRef.current) {
      setCurrentCard(card);
    }
  }, [program, card, prevCardTS]);

  React.useEffect(() => {
    if (currentCard) {
      // removeCurtains() — is called when currentCard component ready
    } else {
      showCurtains();
    }
    // It is intentional, need to run this code only when card has been changed in general:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, currentCard?.id, showCurtains]);

  React.useEffect(() => {
    if (card?.type !== 'Registration' && card?.id) {
      analytics.gtag.event(`CardShow_${card.id}`, `CardShow`, `CardShow_${card.id}`);
    }
  }, [card?.id, card?.type]);

  React.useEffect(() => {
    if (program?.id && card?.type === 'RisingStar' && card.stage === 'StartCheckIn' && !card.isCheckedIn) {
      dispatch(api.endpoints.ViewActivity.initiate({ programId: program?.id, activityId: card.id }));
    }
  }, [card, program?.id, dispatch]);

  React.useEffect(() => {
    const registrationType = program?.registration?.type;

    if (registrationType === 'Native' || registrationType === 'FormAndNative') {
      setAnimationStates((state) => ({ ...state, closeBtn: 'DEFAULT' }));
    }
  }, [program?.registration?.type]);

  const renderCard = () => {
    switch (currentCard?.type) {
      case 'Registration':
        return <RegistrationCard card={currentCard} key={currentCard.timestamp} onReady={removeCurtains} />;

      case 'Info':
        return <InfoCard card={currentCard} key={currentCard.timestamp} onReady={removeCurtains} />;

      case 'RisingStar':
        return <RisingStarCard card={currentCard} key={currentCard.timestamp} onReady={removeCurtains} />;

      case 'YesNo':
        return <YesNoCard card={currentCard} key={currentCard.timestamp} onReady={removeCurtains} />;

      case 'Poll':
        return <PollCard card={currentCard} key={currentCard.timestamp} onReady={removeCurtains} />;

      default:
        return (
          <CSSTransition timeout={0} key="none">
            <div />
          </CSSTransition>
        );
    }
  };

  return (
    <div className={css.card} ref={containerRef}>
      <CloseBtn animationState={animationStates.closeBtn} />
      <Loader animationState={animationStates.loader} cardType={card?.type} bgColor={program?.theme.btnBgColor} />
      <Logo animationState={animationStates.logo} />
      <Curtain animationState={animationStates.curtain} cardType="YesNo" />
      <React.Suspense fallback={<div>loading</div>}>
        <SwitchTransition>{renderCard()}</SwitchTransition>
      </React.Suspense>
    </div>
  );
};
