import React from 'react';
import PubNub from 'pubnub';

import { Action, ModeratorAction } from './actions';
import { config } from './helpers';

interface State {
  pubnub: PubNub;
  isInitialized: boolean;
  isConnected: boolean;
}

export type PubNubMessage<T> = T;

export const reducerFactory =
  (programId: string) =>
  (state: State, action: Action): State => {
    switch (action.type) {
      case 'SEND_ACTION': {
        const message: PubNubMessage<ModeratorAction> = action.payload;
        state.pubnub.publish({ channel: programId, message: JSON.stringify(message) });
        return state;
      }

      case 'SEND_STATS_DATA': {
        const vote = action.payload;
        state.pubnub.publish({ channel: `${programId}_${vote.id}`, message: vote.id });
        return state;
      }

      case 'SET_INITIALIZATION_STATE': {
        return { ...state, isInitialized: action.payload };
      }

      case 'SET_CONNECTION_STATE': {
        return { ...state, isConnected: action.payload };
      }

      default: {
        return state;
      }
    }
  };

const { uuid, subscribeKey, publishKey } = config();
export const initialState: State = {
  pubnub: new PubNub({
    subscribeKey,
    publishKey,
    uuid,
    restore: true,
    // presenceTimeout: 15,
    // keepAlive: true,
    // listenToBrowserNetworkEvents: false,
    // logVerbosity: true,
  }),
  isInitialized: false,
  isConnected: false,
};

export const Context = React.createContext<{
  state: State;
  dispatch: React.Dispatch<Action>;
}>({
  state: initialState,
  dispatch: () => undefined,
});

export const useContextReducer = (params: { programId: string }) => {
  const memoizedReducer = React.useMemo(() => {
    return reducerFactory(params.programId);
  }, [params.programId]);

  return React.useReducer(memoizedReducer, initialState);
};
