import PubNub from 'pubnub';

import { PUBNUB_SUBSCRIBE_KEY, PUBNUB_PUBLISH_KEY } from '@client/constants';
import { BEModel as CardModel } from '@common/models/card';
import { CARD_STAGE } from '@common/models/card/base';
import { ls } from '@client/utils/local-storage';
import { PubNubMessage } from './context';
import { ModeratorAction, StageUpdateData, STAGE_WITH_FULL_DATA } from './actions';

const stageWithFullData: STAGE_WITH_FULL_DATA[] = ['Show', 'StartCheckIn', 'StartVote'];

export const aggregateUpdate = (messages?: (PubNubMessage<ModeratorAction> | undefined)[]) => {
  let updateData: StageUpdateData | undefined;

  messages?.forEach((message) => {
    if (message?.type === 'activity_stage_update') {
      if ((stageWithFullData as CARD_STAGE[]).includes(message.activity.stage)) {
        updateData = message.activity;
      }
      if (message.activity.id === updateData?.id) {
        updateData.stage = message.activity.stage;
      } else {
        updateData = message.activity;
      }
    }
  });

  return updateData;
};

export const aggregateCard = (messages?: (PubNubMessage<ModeratorAction> | undefined)[]) => {
  let card: CardModel | undefined;

  messages?.forEach((message) => {
    if (message?.type === 'activity_stage_update') {
      if ((stageWithFullData as CARD_STAGE[]).includes(message.activity.stage)) {
        card = message.activity as CardModel;
      }
      if (message.activity.id === card?.id) {
        card.stage = message.activity.stage;
      } else {
        card = undefined;
      }
    }
  });

  return card;
};

export const config = () => {
  const PUBNUB_UUID_KEY = 'pubnubUUID';
  let localUUID = ls.getData(PUBNUB_UUID_KEY);

  if (!localUUID?.value) {
    localUUID = { value: PubNub.generateUUID() };
    ls.setData(PUBNUB_UUID_KEY, localUUID);
  }

  return {
    uuid: localUUID.value,
    subscribeKey: PUBNUB_SUBSCRIBE_KEY,
    publishKey: PUBNUB_PUBLISH_KEY,
  };
};
