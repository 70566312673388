import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { MAIN_PAGE_ROUTE, NATIVE_APP_ERROR } from '@client/constants/routes';
import { Head } from '@client/components/common/Head';
import { PubnubConnector } from '@client/components/PubnubConnector';
import Main from '@client/components/pages/Main';
import NativeAppError from '@client/components/pages/NativeAppError';
import NotFound from '@client/components/pages/NotFound';

import '@client/styles/index.scss';
import css from './App.module.scss';

export const App = () => {
  return (
    <div className={css.app}>
      <Head />
      <PubnubConnector>
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path={MAIN_PAGE_ROUTE} element={<Main />} />
          <Route path={NATIVE_APP_ERROR} element={<NativeAppError />} />
        </Routes>
      </PubnubConnector>
    </div>
  );
};
