import { createSlice } from '@reduxjs/toolkit';

import { ProgramState } from './types';
import * as actions from './actions';

type State = ProgramState | null;

const initialState = null as State;

export const program = createSlice({
  name: 'program',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(actions.init.fulfilled, (state, action) => {
      return action.payload;
    });
    builder.addCase(actions.submitRegistration.fulfilled, (state, action) => {
      if (!state) return state;

      state.stage = action.payload.stage;
      state.isPristine = action.payload.isPristine;
    });
    builder.addCase(actions.dismissRegistration.fulfilled, (state, action) => {
      if (!state) return state;

      state.stage = action.payload.stage;
      state.isPristine = action.payload.isPristine;
    });
  },
});

export const programActions = program.actions;
export default program.reducer;
