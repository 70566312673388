import React from 'react';
import { Helmet } from 'react-helmet-async';

interface Props {
  seo?: {
    title?: string;
    description?: string;
  };
  og?: {
    title?: string;
    description?: string;
    imageUrl?: string;
  };
}

export const Head = (props: Props) => {
  const { seo, og } = props;
  const SEO = { title: 'Keshet12 Vote', description: 'LIVE Voting application', ...seo };
  const OG = { title: 'Keshet12 Vote', description: 'LIVE Voting application', ...og };

  return (
    <Helmet>
      <title>{SEO.title}</title>
      <meta name="description" content={SEO.description} />
      <meta property="og:title" content={OG.title} />
      <meta property="og:description" content={OG.description} />
    </Helmet>
  );
};
