export class Resources {
  private static buffer: Record<string, Promise<HTMLImageElement | undefined> | undefined> = {};

  static get = async (url: string, anonymous = false) => {
    if (Resources.buffer[url]) {
      return Resources.buffer[url];
    }

    Resources.buffer[url] = new Promise((resolve) => {
      const image = new Image();

      if (anonymous) image.crossOrigin = 'Anonymous';
      image.addEventListener('error', () => resolve(undefined));
      image.addEventListener('load', () => resolve(image));
      image.src = url;
    });

    return Resources.buffer[url];
  };

  static clear = () => {
    Resources.buffer = {};
  };
}
