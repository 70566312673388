import { createAsyncThunk } from '@reduxjs/toolkit';

import { ls } from '@client/utils/local-storage';
import { BEModel as CardModel } from '@common/models/card';
import {
  CardState,
  PollCardLocalStorageData,
  RisingStarCardLocalStorageData,
  YesNoCardLocalStorageData,
} from '@client/redux/card/types';

type Params = { programId: string; card: CardModel };
type Result = CardState;

export const init = createAsyncThunk<Result, Params, { rejectValue: string }>(
  'card/init',
  (params, { rejectWithValue }) => {
    try {
      const { programId, card } = params;

      switch (card.type) {
        case 'Poll': {
          const localProgramData = ls.getData(params.programId);
          const localCardData: PollCardLocalStorageData | undefined = localProgramData?.[card.id];
          const votes = localCardData?.votes || [];
          const voteStage = localCardData?.voteStage || 'inProgress';

          return { ...card, votes, voteStage, timestamp: Date.now() };
        }

        case 'RisingStar': {
          const localProgramData = ls.getData(programId);
          const localCardData: RisingStarCardLocalStorageData | undefined = localProgramData?.[card.id];
          const isCheckedIn = !!localCardData?.isCheckedIn;
          const answer = localCardData?.answer;

          return { ...card, isCheckedIn, answer, timestamp: Date.now() };
        }

        case 'YesNo': {
          const localProgramData = ls.getData(programId);
          const localCardData: YesNoCardLocalStorageData | undefined = localProgramData?.[card.id];
          const answer = localCardData?.answer;

          return { ...card, answer, timestamp: Date.now() };
        }

        case 'Info': {
          const timestamp = Date.now();

          return { ...card, timestamp };
        }

        default:
          throw new Error(`Unknown card type`);
      }
    } catch (e) {
      return rejectWithValue((e as Error).message);
    }
  },
);
