/* eslint-disable @typescript-eslint/ban-types */
/**
 * https://docs.google.com/document/d/1UWmMoQmHpm5_wOoKFcwCRoEETwDbrQdvzGRNuFdwe-4/edit
 */

type Bridge = Record<string, Function> & {
  call: (iosFunctionName: string, jsObject: string, jsFunctionName: string, callback: string, param?: string) => void;
  callWithCallback: (iosFunctionName: string, callback: Function, param?: string) => void;
  resultForCallback: (callback: (...args: unknown[]) => unknown, result: unknown) => void;
};

let callbackIndex = 0;
const NativeBridge: Bridge = {
  call(iosFunctionName: string, jsObject: string, jsFunctionName: string, callback: string, param?: string) {
    let redirectString = `js-bridge:${iosFunctionName}:${jsObject}:${jsFunctionName}:${callback}`;

    if (param) {
      redirectString = `${redirectString}:${param}`;
    }

    const iframe = document.createElement('iframe');
    iframe.src = redirectString;

    document.body.appendChild(iframe);
  },

  callWithCallback(iosFunctionName: string, callback: Function, param?: string) {
    const callbackKey = `${iosFunctionName}_${++callbackIndex}_callback`;

    this[callbackKey] = callback;
    this.call(iosFunctionName, 'NativeBridge:resultForCallback', `NativeBridge.${callbackKey}`, callbackKey, param);
  },

  resultForCallback(callback: (...args: unknown[]) => unknown, result: unknown) {
    callback.call(null, result);
  },

  testConnectionCallback(...args: unknown[]) {
    console.log(args);
  },

  requestMakoDataCallback(...args: unknown[]) {
    console.log(args);
  },

  closeCallback(...args: unknown[]) {
    console.log(args);
  },
};

// @ts-expect-error It is required to work with NativeApp:
window.NativeBridge = NativeBridge;
export { NativeBridge };
