import { createAsyncThunk } from '@reduxjs/toolkit';

import { ls } from '@client/utils/local-storage';
import { StageUpdateData, STAGE_WITH_FULL_DATA, STAGE_WITH_NO_DATA } from '@client/components/PubnubConnector/actions';
import { CARD_STAGE, BEModel as CardModel } from '@common/models/card';
import {
  CardState,
  PollCardLocalStorageData,
  RisingStarCardLocalStorageData,
  YesNoCardLocalStorageData,
} from '@client/redux/card/types';
import { selectProgram } from '@client/redux/program/selectors';
import { selectCard } from '@client/redux/card/selectors';
import { RootState } from '@client/redux';

type Params = StageUpdateData;
type Result = ({ stage: STAGE_WITH_FULL_DATA } & CardState) | { stage: STAGE_WITH_NO_DATA; id: string };

export const update = createAsyncThunk<Result, Params, { rejectValue: string }>(
  'card/update',
  (params, { rejectWithValue, getState }) => {
    try {
      const state = getState() as RootState;
      const program = selectProgram(state);

      if (!program) {
        throw new Error('Program should be defined at store');
      }

      if (isFullDataParams(params)) {
        switch (params.type) {
          case 'Poll': {
            const localProgramData = ls.getData(program.id);
            const localCardData: PollCardLocalStorageData | undefined = localProgramData?.[params.id];
            const votes: string[] = localCardData?.votes || [];
            const voteStage = localCardData?.voteStage || 'inProgress';
            const timestamp = Date.now();

            return { ...params, votes, voteStage, timestamp };
          }

          case 'RisingStar': {
            const localProgramData = ls.getData(program.id);
            const localCardData: RisingStarCardLocalStorageData | undefined = localProgramData?.[params.id];
            const isCheckedIn = !!localCardData?.isCheckedIn;
            const answer = localCardData?.answer;

            const card = selectCard(state);
            let timestamp = Date.now();

            if (card?.id === params.id) {
              const cardLifecircle: CARD_STAGE[] = [
                'Show',
                'StartCheckIn',
                'StartVote',
                'StopCheckIn',
                'StopVote',
                'Hide',
              ];
              const currentStageLifecircleIndex = cardLifecircle.indexOf(card.stage);

              if (
                params.stage === 'StartCheckIn' ||
                cardLifecircle.indexOf(params.stage) < currentStageLifecircleIndex
              ) {
                timestamp = Date.now();
              } else {
                timestamp = card.timestamp;
              }
            }

            return { ...params, isCheckedIn, answer, timestamp };
          }

          case 'YesNo': {
            const localProgramData = ls.getData(program.id);
            const localCardData: YesNoCardLocalStorageData | undefined = localProgramData?.[params.id];
            const answer = localCardData?.answer;
            const timestamp = Date.now();

            return { ...params, answer, timestamp };
          }

          case 'Info': {
            const timestamp = Date.now();

            return { ...params, timestamp };
          }

          default:
            break;
        }
      }

      return params;
    } catch (e) {
      return rejectWithValue((e as Error).message);
    }
  },
);

const isFullDataParams = (params: Params): params is { stage: STAGE_WITH_FULL_DATA } & CardModel => {
  const stageWithFullData: STAGE_WITH_FULL_DATA[] = ['Show', 'StartCheckIn', 'StartVote'];

  // It is agreed that backend sends full data if stage has value STAGE_WITH_FULL_DATA
  // Taking into account, that <PubnubConnector /> aggregates messages because of debounced handler
  // it is possible that full data could come with stage !== STAGE_WITH_FULL_DATA, to handle this case alternating
  // condition is added: 'type' in params.
  return (stageWithFullData as CARD_STAGE[]).includes(params.stage) || 'type' in params;
};
