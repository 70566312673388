import { createSlice } from '@reduxjs/toolkit';

import { CARD_STAGE } from '@common/models/card';
import { STAGE_WITH_FULL_DATA } from '@client/components/PubnubConnector/actions';
import { submitRegistration } from '@client/redux/program/actions/submit-registration';
import {
  CardState,
  RisingStarCardLocalStorageData,
  PollCardLocalStorageData,
  YesNoCardLocalStorageData,
} from './types';
import * as actions from './actions';

type State = CardState | null;
const initialState = null as State;

export const card = createSlice({
  name: 'card',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(actions.init.fulfilled, (state, action) => {
      if (action.payload.stage !== 'Hide') {
        return action.payload;
      }
      return null;
    });
    builder.addCase(actions.update.fulfilled, (state, action) => {
      const stageWithFullData: STAGE_WITH_FULL_DATA[] = ['Show', 'StartCheckIn', 'StartVote'];

      if ((stageWithFullData as CARD_STAGE[]).includes(action.payload.stage)) {
        return action.payload as State;
      } else {
        if (state?.id === action.payload.id) {
          if (action.payload.stage !== 'Hide') {
            state.stage = action.payload.stage;
          } else {
            return null;
          }
        } else {
          // this case means that during debounce in PubNubConnector, update is aggregated for dirrefent card
          // not current one in store, and it have stage: STAGE_WITH_NO_DATA with full card data:
          if (action.payload.stage !== 'Hide') {
            return action.payload as State;
          } else {
            return null;
          }
        }
      }
    });
    builder.addCase(actions.checkIn.fulfilled, (state) => {
      if (state?.type === 'RisingStar') {
        state.isCheckedIn = true;
      }
    });
    builder.addCase(actions.setAnswer.fulfilled, (state, action) => {
      if (state?.type === 'RisingStar' || state?.type === 'YesNo') {
        state.answer = action.payload.answer;
      }
    });
    builder.addCase(actions.setVote.fulfilled, (state, action) => {
      if (state?.type === 'Poll') {
        state.votes = action.payload;
        state.voteStage = 'done';
      }
    });
    builder.addCase(actions.voteAgain.fulfilled, (state, action) => {
      if (state?.type === 'Poll') {
        state.voteStage = action.payload;
      }
    });
    builder.addCase(submitRegistration.fulfilled, (state, action) => {
      if (!state?.id) return;
      const currentCardLocalStorageData = action.payload.cards[state.id];

      if (currentCardLocalStorageData) {
        switch (state?.type) {
          case 'RisingStar': {
            const risingStarCardLocalStorageData = currentCardLocalStorageData as RisingStarCardLocalStorageData;

            state.isCheckedIn = !!risingStarCardLocalStorageData.isCheckedIn;
            state.answer = risingStarCardLocalStorageData.answer;
            break;
          }

          case 'YesNo': {
            const yesNoCardLocalStorageData = currentCardLocalStorageData as YesNoCardLocalStorageData;

            state.answer = yesNoCardLocalStorageData.answer;
            break;
          }

          case 'Poll': {
            const pollCardLocalStorageData = currentCardLocalStorageData as PollCardLocalStorageData;

            state.votes = pollCardLocalStorageData.votes || [];
            break;
          }

          default:
            break;
        }
      }
    });
  },
});

export const cardActions = card.actions;
export default card.reducer;
