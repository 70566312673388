import React, { CSSProperties } from 'react';
import gsap from 'gsap';
import cn from 'classnames';

import { CARD_TYPE } from '@common/models/card';
import { withTransition, WithTransitionProps } from '@client/components/HOCs/with-transition';
import { useAppSelector, usePrev } from '@client/hooks';
import { selectProgram } from '@client/redux/program/selectors';
import css from './Card.module.scss';

type Props = WithTransitionProps & {
  className?: string;
  animationState?: 'FROM_TOP' | 'TO_BOTTOM';
  cardType: CARD_TYPE;
};

const Curtain = (props: Props) => {
  const { cardType, className = '' } = props;
  const program = useAppSelector(selectProgram);
  const prevAnimationState = usePrev(props.animationState);
  const isRegisteredOnStartRef = React.useRef(program?.stage);
  const onTransitionEndDelayedCallRef = React.useRef<GSAPTween | null>(null);
  const bgImgUrl = program?.theme.bgImage;
  const bgColor = program?.theme.bgColor || 'transparent';
  const bgStyle: CSSProperties = {};

  if (bgImgUrl) bgStyle.backgroundImage = `url(${bgImgUrl})`;
  if (bgColor) bgStyle.backgroundColor = bgColor;

  React.useEffect(() => {
    if (prevAnimationState !== 'FROM_TOP' && props.animationState === 'FROM_TOP') {
      gsap.fromTo(
        `.${css.curtain}`,
        { opacity: 0, transition: '' },
        { duration: 0, opacity: 1, transition: `opacity 750ms linear` },
      );
      if (isRegisteredOnStartRef.current) {
        gsap.fromTo(
          `.${css.curtainBg}`,
          { opacity: 0, transition: '' },
          { duration: 0, opacity: 1, transition: 'opacity 1s ease-in 0.25s' },
        );
      }
    }
    if (prevAnimationState !== 'TO_BOTTOM' && props.animationState === 'TO_BOTTOM') {
      gsap.fromTo(
        `.${css.curtain}`,
        { opacity: 1, transition: '' },
        { duration: 0, opacity: 0, transition: `opacity 750ms linear` },
      );
    }

    onTransitionEndDelayedCallRef.current?.kill();
    onTransitionEndDelayedCallRef.current = gsap.delayedCall(1, props.onTransitionEnd);
  }, [prevAnimationState, props.animationState, props.onTransitionEnd]);

  React.useEffect(() => {
    return () => {
      gsap.killTweensOf(`.${css.curtain}`);
      gsap.killTweensOf(`.${css.curtainBg}`);
    };
  }, []);

  return (
    <div className={cn(css.curtain, css[cardType], className)} style={{ backgroundColor: program?.theme.bgColor }}>
      <div className={css.curtainBg} style={bgStyle} />
    </div>
  );
};

const Animated = withTransition(Curtain, { outStates: ['TO_BOTTOM'] });
export { Animated as Curtain };
